import React, { useState } from "react";
import Splash from "./components/SplashScreen";
import { ThemeProvider } from "styled-components";
import './App.css';
import './animate.css';

const LightTheme = {
  pageBackground: "#f4f4f4",
  titleColor: "#823BD7",
  tagLineColor: "#313131"
}

const DarkTheme = {
  pageBackground: "#313131",
  titleColor: "#823BD7",
  tagLineColor: "#F4F4F4"
}

const themes = {
  light: LightTheme,
  dark: DarkTheme,
}

function App() {
  const [theme, setTheme] = useState("light");
  return (
    <ThemeProvider theme={themes[theme]}>
      <Splash theme={theme} setTheme={setTheme} />
    </ThemeProvider>
  );
}

export default App;
