import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

function Home() {
    return (
        <section>
            <center>
                <div>
                    <img id="cybersentio" src={process.env.PUBLIC_URL + '/images/CyberSentio.png'} />
                </div>
            </center>
            <br /><br />
            <center>
                <div class="socialbar">
                    <a href="https://www.linkedin.com/company/cybersentio/"><FaLinkedinIn size={35} class="socialicons" /></a>
                    <a href="https://www.facebook.com/cybersentio/"><FaFacebookSquare size={35} class="socialicons" /></a>
                    <a href="https://www.instagram.com/cybersentio/"><FaInstagram size={35} class="socialicons" /></a>
                    <a href="https://www.youtube.com/channel/UC6RFdZJ4_ZU0P_o0ko0OXNQ/"><FaYoutube size={35} class="socialicons" /></a>
                </div>
            </center>
            <br /><br />
            <h1>Watch this space</h1>
        </section>

    );
}

export default Home;