import styled from "styled-components";
import { CgSun } from "react-icons/cg";
import { HiMoon } from "react-icons/hi";
import Header from './header';
import Footer from './footer';
import Home from './home';
import ls from 'local-storage';

const Toggle = styled.button`
    cursor: pointer;
    height: 42px;
    width: 42px;   
    border-radius: 25%;
    border: none;
    tex-align center;
    background-color: ${props => props.theme.titleColor};
    color: ${props => props.theme.pageBackground};
    &:focus {
        outline: none;
    }
    transition: all .8s ease;
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.pageBackground};
  transition: all .5s ease;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    content-align: center;
    position: fixed;
    right: 20px;
    top: 12%;
`;

const Title = styled.h1`
    color: ${props => props.theme.titleColor};
    transition: all .5s ease;
`;

const TagLine = styled.span`
    color: ${props => props.theme.tagLineColor};
    font-size: 18px;
    transition: all .5s ease;
`;

    function Splash(props) {
        function changeTheme() {
            if (props.theme === "light") {
                props.setTheme("dark");
                console.log("dark");
            } else {
                props.setTheme("light");
                console.log("light");
            }
        };

    const icon = props.theme === "light" ? <HiMoon size={25} /> : <CgSun size={25} />;

    return (
        <Page>
            <center>
                <Container>
                    <Toggle id="toggler" onClick={changeTheme}>
                        {icon}
                    </Toggle>
                </Container>
                <Header></Header>
                <Home></Home>
                <Footer></Footer>
            </center>
        </Page>
    );
};

export default Splash;